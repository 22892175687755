<template>
  <v-container fluid>
    <Pie
      :chart-options="chartOptions"
      :chart-data="statisticChartDataPaymentsTypes"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </v-container>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import { mapState, mapActions } from 'vuex'

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, ArcElement)

export default {
  name: 'FinancialStatementPaymentsTypes',

  components: { Pie },

  props: {
    chartId: {
      type: String,
      default: 'pie-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          enabled: true,
          // mode: 'point',
          callbacks: {
            label: function (context) {
              const label = context.label || ''
              const percent = context.dataset.percents[context.dataIndex]
              return `${label} (${percent}%)`
            },
            afterLabel: function (context) {
              const count = context.dataset.data[context.dataIndex]
              const sum = context.dataset.amount[context.dataIndex]
              return [`Кількість: ${count} шт.`, `Сума: ${sum} грн.`]
            },
          },
        },
      },
    },
  }),

  computed: {
    ...mapState('payments', ['statisticChartPayload', 'statisticChartDataPaymentsTypes']),
  },

  watch: {
    statisticChartPayload: {
      handler(newValue) {
        this.initialize(newValue)
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions('payments', ['loadStatisticPaymentsTypes']),

    initialize() {
      this.loadStatisticPaymentsTypes(this.statisticChartPayload)
    },
  },
}
</script>

<style scoped></style>
